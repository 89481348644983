<template>
  <div>
    <Accountactivate></Accountactivate>
  </div>
</template>

<script>
// @ is an alias to /src
import Accountactivate from '@/components/Accountactivate.vue'

export default {
  name: 'Accountactivateview',
  components: {
    Accountactivate,
  }
}
</script>
