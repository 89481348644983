<template>
    <div>
        
    </div>
</template>
<script>
import DataService from "../services/DataService";
export default {
    metaInfo: function() {
      return {
        title: this.$t("metatag.titledefault"),
        titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
        meta: [
        { name: 'description', content:  this.$t("metatag.description") },
        { name: 'keywords', content:  this.$t("metatag.keywords") }
        ]
      }
    },
    methods: { 
      sessionKontrol() {
                if (this.$session.get("logtoken")) {
                    window.location.href = `/${this.$session.get("lang")}/dashboard`;
                }
      },
      activate() {
          var pathname = window.location.pathname.split("/"),
            key = pathname[4],
            lang = pathname[1];
          DataService.accountActivate(key)
          .then(response => {  
              if(response.data.status=="ok"){
                this.$vToastify.setSettings({position:"bottom-right",theme:"light"});
                this.$vToastify.success("", this.$t('message.'+response.data.success));
                this.$router.push('/'+lang+'/login')
              }else{
                this.$vToastify.setSettings({position:"bottom-right",theme:"light"});
                this.$vToastify.warning("", this.$t('message.'+response.data.error));
                this.$router.push('/'+lang+'/login')
              }
          })
          .catch(e => {
            console.log(e);
          });
      },
      
    },
    mounted() {
      this.activate();
      this.sessionKontrol()
    },
}
</script>